<template>
  <div class="pos-r">
    <label class="input">
      <input
        ref="input"
        v-model="value"
        :type="type"
        class="input__fluid"
        value=""
        :id="idInput"
        @input="onInput"
      />
      <span class="input__placeholder" @click="clear">{{ placeholder }}</span>
      <span class="input__helper-text">{{ errorMessage }}</span>
      <div class="input__icon">
        <div class="icon-cross" @click="clear"></div>
        <div class="icon-error"></div>
      </div>
    </label>
    <div
      v-if="this.searchResults.length > 0 && this.value && !isSelected"
      class="input-hints__wrapper pos-a"
    >
      <div
        v-for="i in searchResults"
        :key="i.name"
        class="input-hints__item"
        @click="selectItem(i.name)"
      >
        {{ i.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { capitalizeFirstLetter } from "@/functions/capitalizeFirstLetter";

export default {
  name: "app-input",
  data() {
    return {
      value: "",
      isSelected: false,
    };
  },
  props: {
    searchData: {},
    placeholder: {
      type: String,
      isRequired: true,
    },
    type: {
      type: String,
      default: "text",
    },
    errorMessage: {
      type: String,
      default: "Заполните поле",
    },
    idInput: {
      type: String,
      default: "",
    },
    valueInput: {
      type: String,
      default: "",
    },
  },
  watch: {
    value() {
      this.$refs.input.setAttribute("value", this.value);
      this.$emit("appInput", this.value);
    },
  },
  created() {
    if (this.valueInput) {
      this.value = this.valueInput;
      this.isSelected = true;
      this.$emit("inputValue", this.value);
    }
  },
  methods: {
    onInput() {
      this.isSelected = false;
    },
    selectItem(name) {
      this.isSelected = true;
      this.value = name;
      this.$emit("inputValue", this.value);
    },
    clear() {
      this.value = "";
      this.$emit("inputValue", "");
    },
  },
  computed: {
    searchResults() {
      if (this.searchData)
        return this.searchData.filter((i) =>
          i.name.match(capitalizeFirstLetter(this.value))
        );
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  --placeholder: rgba(255, 255, 255, 0.6);
  --input-text: #ffffff;
  --caret: #3ec5fc;
  --background-input: rgba(255, 255, 255, 0.15);
  --disabled: #f5f5f7;
  --error: #d51a1a;
  --error-shadow: #eea3a3;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__placeholder {
    font-style: normal;
    font-weight: normal;
    color: var(--placeholder);
    position: absolute;
    left: 24px;
    top: 18px;
    transform-origin: 0 0;
    /* transform-origin: 0 0;
    transform: translate(0, 18px); */
    background-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    max-width: 95%;
    overflow: hidden;
    transition: transform 0.3s linear, color 0.3s linear, top 0.3s linear;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  &__helper-text {
    display: none;
    position: absolute;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-size: 12px;
  }

  &__fluid {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: hidden;
    text-overflow: ellipsis;
    background: var(--background-input);
    height: 60px;
    box-sizing: border-box;
    border: none;
    caret-color: var(--caret);
    padding: 26px 48px 6px 24px;
    outline: none;
    font-style: normal;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: var(--input-text);
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 18px;
    right: 18px;
    z-index: 50000;
  }

  &__icon > * {
    width: 100%;
    height: 100%;
  }

  &__icon .icon-error {
    display: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyQzIgMTcuNTIgNi40OCAyMiAxMiAyMkMxNy41MiAyMiAyMiAxNy41MiAyMiAxMkMyMiA2LjQ4IDE3LjUyIDIgMTIgMlpNMTMgMTdIMTFWMTVIMTNWMTdaTTEzIDEzSDExVjdIMTNWMTNaIiBmaWxsPSIjRDUxQTFBIi8+Cjwvc3ZnPgo=");
  }

  &__icon .icon-cross {
    cursor: pointer;
    display: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDZMNiAxOCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYgNkwxOCAxOCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  }
}

.input__fluid:focus ~ .input__placeholder,
.input__fluid:not([value=""]) ~ .input__placeholder {
  top: 6px;
  transform: scale(0.75);
}

.input__fluid:disabled {
  background: var(--disabled);
}

.input__fluid:disabled ~ .input__icon {
  display: none;
}

.input__fluid:disabled ~ .input__placeholder {
  background: rgba(0, 0, 0, 0);
}

.input__fluid:not([value=""]) ~ .input__icon .icon-cross {
  display: inline-block;
}

.input__icon .icon-cross:hover,
.input__icon .icon-cross:active {
  display: inline-block !important;
}

.input__fluid[value=""] ~ .input__icon .icon-cross {
  display: none !important;
}

.error .input {
  &__fluid {
    caret-color: var(--error);
  }

  &__icon .icon-cross {
    display: none !important;
  }

  &__icon .icon-error {
    display: inline-block;
  }

  &__placeholder {
    color: var(--error) !important;
  }

  &__helper-text {
    color: var(--error);
    display: inline;
  }
}

@media (max-width: 1023px) {
  .input__fluid {
    height: 48px;
    font-size: 16px;
    padding: 24px 46px 6px 18px;
  }
  .input__placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    top: 14px;
    left: 18px;
  }
  .input__icon .icon-cross {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjA5MzggNS4zNzVMNS4wOTM3NSAxNC4zNzUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41ODIwMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01LjA5Mzc1IDUuMzc1TDE0LjA5MzggMTQuMzc1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNTgyMDMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
  }
  .input__icon {
    width: 18px;
    height: 18px;
    top: 15px;
    right: 15px;
  }
}
</style>