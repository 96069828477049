<template>
  <router-link class="menu-btn" to="/">
    <svg-icon class="menu-btn__icon" height="60" name="menu-btn" width="60"/>
  </router-link>
</template>

<script>
import SvgIcon from "./IconBase";

export default {
  name: "BtnMenu",
  components: {SvgIcon},
};
</script>

<style>
.menu-btn {
  display: block;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 31px;
}

.menu-btn__icon {
  display: block;
  width: 100%;
  height: 100%;
}

@media (max-width: 1023px) {
  .menu-btn {
    top: 36px;
    width: 48px;
    height: 48px;
  }
}
</style>